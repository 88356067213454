.linkLight, .linkDark {
    text-decoration: none;
}

.linkLight {
    color: black;
}

.linkDark {
    color: white;
}